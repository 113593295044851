import { Injector } from '@angular/core';

import { Base } from './base.class';

/**
 * 所有非页面级组件需要继承此类。
 */
export abstract class BaseComponent extends Base {
  protected constructor(protected injector: Injector) {
    super(injector);
  }

  protected abstract generateRequestBaseParams(): object | void;

  removeEmptyRequestParams<T>(params: T): T {
    return Object.entries(params).reduce((acc, cur) => {
      const [key, value] = cur;

      if (value === null || value === undefined) {
        return acc;
      } else {
        return { ...acc, [key]: value };
      }
    }, {}) as T;
  }
}
