import { Observable } from 'rxjs';
import { map , tap } from 'rxjs/operators';
import { _HttpClient } from '@delon/theme';
import { Injector } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';

export class IHttpResponse<T> {
  code: number;
  requestId: string;
  data: T;
  msg?: string;
}

export class ApiService{

  constructor(
            protected injector: Injector,
            protected http: _HttpClient,
            protected message: NzMessageService,
            ) {}

  /**
   * @description Wrapped http get method
   */

  protected get<U>(url: string, params: { [key: string]: any }): Observable<U> {
    return this.http
      .get<IHttpResponse<U>>(url , params )
      .pipe(
        tap((res) => {
          if (res.code !== 0) {
              this.message.error(res.msg || '加载失败');
          }
          return res;
        }),
        map((res) => (res instanceof IHttpResponse ? res.data : res))
      );
  }

  /**
   * @description Wrapped http post method
   */

  protected post<U>(url: string, params: { [key: string]: any }): Observable<U> {
    return this.http
      .post<IHttpResponse<U>>(url, params )
      .pipe(
        tap((res) => {
          if (res.code !== 0) {
              this.message.error(res.msg || '加载失败');
          }else{
              this.message.success(res.msg || '操作成功');
          }
          return res;
        }),
        map((res) => (res instanceof IHttpResponse ? res.data : res))
      );
  }
  

  /**
   * @description Wrapped http put method
   */
  protected put<U>(url: string,  params: { [key: string]: any }): Observable<U> {
    return this.http.put<IHttpResponse<U>>(url, params).pipe(
      tap((res) => {
        const { code } = res;
        if (code === 0) {
          this.message.success(res.msg || '操作成功');
        } else {
          this.message.error(res.msg || '操作失败');
        }
      }),
      map((res) => (res instanceof IHttpResponse ? res.data : res))
    );
  }
  /**
   * @description Wrapped http delete method
   */
  protected delete<U>(url: string,  params: { [key: string]: any }): Observable<U> {
    return this.http.delete(url, params).pipe(
      tap((res) => {
        const { code } = res;

        if (code === 0) {
          this.message.success(res.msg || '操作成功');
        } else {
          this.message.error(res.msg || '操作失败');
        }
      }),
      map((res) => (res instanceof IHttpResponse ? res.data : res))
    );
  }
}
