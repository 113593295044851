

import { Subject } from 'rxjs';

import { Injector } from '@angular/core';

import { BaseComponent } from './base-component.class';

export abstract class BaseTable extends BaseComponent {

  abstract rows: any[];

  abstract total: number;

  // tslint:disable-next-line:no-any
  abstract update: Subject<any>;

  abstract pageIndex: number;

  abstract pageSize: number;

  initialPageIndex = 1;

  initialPageSize = 20;

  constructor(protected injector: Injector) {
    super(injector);
  }

  // tslint:disable-next-line:no-any
  protected abstract initTableModel(...args: any[]): void;

  /**
   * Get request params in order to get source data through api
   */
  protected abstract generateTableRequestParams(params?: object): object | void;

  /**
   * Get parameters other than table parameters;
   * Some requisition need extra params, these params may come from other places, such as form,
   * constants which required in api.
   */
  protected abstract getRequestParamsOtherThanTable(params?: object): object | void;

  getPosition(index: number): number {
    return (this.pageIndex - 1) * this.pageSize + index + 1;
  }
}
