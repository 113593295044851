import { Observable, Subject } from 'rxjs';

import { Injector, OnDestroy } from '@angular/core';

export interface XinAnYunConfig {
  domain: string;
}

export type CompareFn<T> = (pre: T, cur: T) => boolean;

/**
 * super class of all directives and services.
 */
export abstract class Base implements OnDestroy {
  protected destroy$ = new Subject();

  constructor(protected injector: Injector) {
  }

  /**
   * OnDestroy is called when a directive, pipe or service is destroyed.
   * So both directives and services can extend this method to do some clean up.
   * @see [LifeCycle Hooks] https://angular.io/api/core/OnDestroy
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * 取出 Observable 中的结果;
   */
  protected unwrapObservable<T>(obs: Observable<T>): T {
    let result = null;

    obs.subscribe((res) => (result = res));

    return result;
  }
}
