import { Injector } from '@angular/core';

import { BaseTable } from './base.table.component';

export abstract class BaseCheckableTable extends BaseTable {
  constructor(protected injector: Injector) {
    super(injector);
  }

  loading = false;

  searchParams: any = {};

  isAllDisplayDataChecked = false;

  isIndeterminate = false;

  mapOfCheckedId: { [key: string]: boolean } = {};

  numberOfChecked = 0;

  checkedIds: string[];

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.rows
      .filter(item => !item.disabled)
      .every((item, index) => this.mapOfCheckedId[index]);

    this.isIndeterminate =
      this.rows
        .filter(item => !item.disabled)
        .some((item, index) => this.mapOfCheckedId[index]) &&
      !this.isAllDisplayDataChecked;

    this.numberOfChecked = this.rows.filter(
      (item, index) => this.mapOfCheckedId[index],
    ).length;

    this.checkedIds = Object.entries(this.mapOfCheckedId)
      .filter(([id, checked]) => checked)
      .map(([id]) => id);
  }

  checkAll(value: boolean): void {
    this.rows
      .filter(item => !item.disabled)
      .forEach((item, index) => (this.mapOfCheckedId[index] = value));

    this.refreshStatus();
  }

  getSelectedRowIds(): string[] {
    return this.rows
      ? this.rows
          .filter((item, index) => this.mapOfCheckedId[index])
          .map(item => item.id)
          .filter(id => !!id)
      : [];
  }

  protected clearChecked() {
    this.mapOfCheckedId = {};

    this.refreshStatus();
  }
}
